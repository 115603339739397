// import { width } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";
import { UserFooterBar } from '../uiComponents/UserFooterBar';
import '../Components/Component-css/Common.css';

const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById('sidebarOverlay').style.display = 'none';
};
const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById('sidebarOverlay').style.display = 'block';
};
// const w3_close = () => {
//   const width = document.getElementById("mySidebar").offsetWidth;
//   document.getElementById("mySidebar").style.left = `-${width}px`;
//   document.getElementById('sidebarOverlay').style.display = 'none';
// };
// const w3_open = () => {
//   document.getElementById("mySidebar").style.left = "0";
//   document.getElementById('sidebarOverlay').style.display = 'block';
// };

const Header = ({ user, loggedIn }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [imageSrc, setImageSrc] = useState('');
  const [error, setError] = useState(false);

  const history = useHistory()
  let access_token = localStorage.getItem("token")
  access_token = localStorage.getItem("token")

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setError(false);
    return setWebsiteSettings(data);
  }
  document.title = (WebSitesettings) ? WebSitesettings.WebTitle : 'Skill based game';
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
  }, [])

  const handleImageError = () => {
    setError(true);
    setImageSrc(process.env.PUBLIC_URL + '/Images/kd_ludo/logo.png');
  };

  // logout user 
  const logout = () => {

    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `logout`, {
      headers: headers
    }, { headers })
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token")
        window.location.reload()
        history.push('/')
      }).catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');

        }
      })

  }

  return (
    <div>


      {access_token ? (
        <React.Fragment>
          <div id='sidebarOverlay' onClick={w3_close}></div>



          <div className="w3-sidebar w3-bar-block w3-sidebar-text" id="mySidebar" style={{ paddingBottom: '70px' }}>

            <div className="close_nav" >
              <i className="fa-regular fa-circle-xmark" onClick={w3_close}></i>
            </div>
            <Link to={"/Profile"} className="w3-bar-item w3-button" onClick={w3_close}>


              <picture className="icon">
                {user && user.avatar ? (<img width="30px" height="30px" src={baseUrl + `${user && user.avatar}`} alt="profile" style={{ borderRadius: "50px" }} />) : (<img src='/Images/Header/user.png' width="28px" alt="profile" />)}
              </picture>

              <div style={{ marginLeft: '.5rem' }} >
                My Profile
              </div>
            </Link>

            <Link to={"/landing"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/gamepad.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Win cash
              </div>

            </Link>
            <Link to={"/wallet"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/wallet.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                My wallet
              </div>

            </Link>

            <Link to={"/Gamehistory"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/gamesHistory.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Game History
              </div>

            </Link>




            <Link to="/transaction-history" className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/order-history.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Transaction History
              </div>

            </Link>

            <Link to={"/refer"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/sreferEarn.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Refer and Earn
              </div>

            </Link>

            <Link to={"/Referral-history"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/refer-history.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Refer History
              </div>

            </Link>

            <Link to={"/Notification"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/notifications.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Notification
              </div>

            </Link>



            <Link to={"/support"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/support.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Support
              </div>

            </Link>
            <a href={process.env.PUBLIC_URL + '/Moneyludo.apk'} download className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="Download APK" src={process.env.PUBLIC_URL + '/Images/Header/download.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Download APK
              </div>
            </a>

            <Link to={"/term-condition"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/terms.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>
                Term & Conditions
              </div>

            </Link>
            {/*   <Link className="w3-bar-item w3-button" to="!/" onClick={(e) => logout(e)}>
          <picture className="icon">
            <img alt="img" src={process.env.PUBLIC_URL + '/Images/logout.png'} />
          </picture>
          <div style={{ marginLeft: '.5rem' }}>
            Logout
          </div>

        </Link> */}
          </div>
          {/* w3-button w3-teal w3-xlarge */}

          <div className="w3-teal">
            <div className="w3-container ">
              <div className={`${css.headerContainer} Nav_headerContainer`} style={{borderBottom: "#FEC637 1px solid"}}>

                <div className='row w-100'>

                  <div className='col-2'
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                      className=" float-left top_nav_btn"
                      onClick={w3_open} id="hambergar"
                      style={{ color: '#d0e1f9' }}
                    >
                      {/* <picture className={`${css.sideNavIcon} mr-0`}>
                    <img
                      src={process.env.PUBLIC_URL + '/Images/LandingPage_img/sidebar.png'}
                      className="snip-img"
                      alt=''
                    />
                  </picture> */}
                      <i className="fa-solid fa-bars" style={{ fontSize: "1.3rem" }} ></i>
                    </button>
                  </div>

                  <div className='col-5'
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link to="/" style={{ marginRight: "auto" }}>
                      <picture className={`ml-2 ${css.navLogo} d-flex download_bar_logo`}>

                        {error ? (
                          <img src={process.env.PUBLIC_URL + '/Images/kd_ludo/logo.png'} alt="Logo" className="snip-img img-fluid" />
                        ) : (
                          <img
                            src={baseUrl + WebSitesettings.SmallLogo}
                            className="snip-img img-fluid"
                            alt='Logo'
                            onError={handleImageError}
                          />
                        )}
                      </picture>
                    </Link>
                  </div>

                  <div className='col-5'
                    style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <div className={`${css.menu_items}`}>
                      <Link className={`${css.box} `} to="/Addcase" style={{background: "#fff", marginTop: "8px"}}>
                        <div className={`${css.moneyBox_header}`}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/Images/LandingPage_img/global-rupeeIcon.gif"
                            }
                            alt=""
                            width="24px" />
                        </div>
                        <div className={`${css.moneyBox_text} `}>{user && user.Wallet_balance}</div>
                        <picture className={`${css.walletAdd}`}>
                          <img
                            src={process.env.PUBLIC_URL + '/Images/LandingPage_img/addSign.png'}
                            className="snip-img"

                          />
                        </picture>
                      </Link>

                      {/*<a
                    href="http://www.youtube.com/@brothersludo"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <picture className={`${css.moneyIcon_container}`}>
                      <img
                        src={process.env.PUBLIC_URL + '/Images/LandingPage_img/notification-activity-reward.gif'}
                        className="snip-img notification_coin"
                        alt=''
                      />
                    </picture> 
                    <div className={`${css.moneyBox_header}`}>Redeem Cash</div>
                    */}

                      {/* <div>
                        <picture className={`${css.moneyIcon_container}`}>
                        <img style={{ width: '100%'}}
                            src={process.env.PUBLIC_URL + '/Images/youtubebtn.png'}
                            className="snip-img notification_coin"
                            alt=''
                          />
                        </picture>
                    </div> 

                    
                  </a>*/}
                    </div>
                  </div>


                </div>

                {/* <span className="mx-5"></span>
          
                <span className="mx-5"></span> */}
              </div>
            </div>
          </div>
          <div id="userFooterBar">
            <UserFooterBar />
          </div>
        </React.Fragment>


      ) : (
        <div className="w3-teal">
          <div className="w3-container ">
            <div className={`${css.headerContainer} justify-content-between`}>

              <Link to="/" style={{ width: '80%' }} >
                <picture className={`ml-2 ${css.navLogo} d-flex`}>

                  {error ? (
                    <img src={process.env.PUBLIC_URL + '/Images/kd_ludo/logo.png'} alt="Logo" className="snip-img img-fluid navLogo_logo2" />
                  ) : (
                    <img
                      src={baseUrl + WebSitesettings.SmallLogo}
                      className="snip-img img-fluid navLogo_logo2"
                      alt='Logo'
                      onError={handleImageError}
                    />
                  )}
                </picture>
              </Link>

              <div className={`ml-5`}>
                <Link type="button" className="login_button" to="/login">LOGIN</Link>
              </div>
            </div>
          </div>
        </div>
      )
      }

      { /*
      <div className="alert alert-danger mt-5 pt-5" role="alert">
        <strong>
      असुविधा के लिए खेद है,हमारे पेमेंट गेटवे पार्टनर की तकनीकी खामियों की वजह से डिपॉजिट और विड्रवाल मैं  समस्या आ रही है । कृपया सभी प्लेयर्स से अनुरोध है की धैर्य बनाए रखे आपका अमाउंट सेफ है और समस्या के निवारण मैं 48 से 72 घंटे लग सकते
        </strong>
      </div>
      */}
    </div>
  );
};

export default Header;
